body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  background-color: #fefefe;
}

.App {
  width: 100vw;
  display: flex;
  flex-direction: row;
}

.line-container canvas{
  margin-left: -5px;
  margin-top: 2px;
  width: 104% !important;
}
