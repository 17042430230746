/* Global Scrollbar Styles */


body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
}
.App {
    overflow: hidden;
    display: flex;
    flex-direction: row;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#wisdom-container iframe {
    width: auto !important;
    height: 99vh !important;
}
.custom-nav li {
    cursor: pointer;
    margin-bottom: 10px;
}

#reject-call-button:disabled, #accept-call-button:disabled{
    background-color: grey;
    color: white;
    cursor: not-allowed;
    opacity: 0.6;
}

.full-width {
    width: 100%;
}

.table-column{
    border-right: 1px solid #e0e0e0;
}
.justify-center{
    justify-content: center;
}
.button-hover:hover{
    color: #1a8ca8 !important;
}
.transcript-title{
    font-size: 1em;
}
.transcript-body:not(:first-child) {
    margin-top: 20px;
}